import { useState } from "react";
import briefcase from "assets/briefcase.png";
import {
  Card,
  CardContent,
  Typography,
  Radio,
  FormControl,
  FormControlLabel,
} from "@mui/material";

const QuestionCard = ({ question, options = [], feedback, onOptionSelect, icon }) => {
  const [selectedValue, setSelectedValue] = useState(null);

  const handleChange = (event) => {
    onOptionSelect(event.target.value);
    setSelectedValue(event.target.value);
  };
  return (
    <Card sx={{ mb: 2 }}>
      <CardContent>
        {feedback ? (
          <div style={{ display: "grid", justifyItems: "center", marginTop: "20px" }}>
            <img
              src={icon}
              alt={icon + " Icon"}
              style={{ width: "50px", height: "50px", marginBottom: "10px" }}
            />
            <Typography variant="h6" sx={{ textAlign: "center", color: "#000" }}>
              {feedback}
            </Typography>
          </div>
        ) : (
          <>
            <Typography variant="h6">{question}</Typography>
            <FormControl component="fieldset">
              {options.map((option, index) => (
                <FormControlLabel
                  key={index}
                  control={
                    <Radio
                      checked={selectedValue === option.value}
                      onChange={handleChange}
                      value={option.value}
                      name="radio-buttons"
                    />
                  }
                  label={option.label}
                />
              ))}
            </FormControl>
          </>
        )}
      </CardContent>
    </Card>
  );
};

export default QuestionCard;
