import { useEffect, useState, useContext } from "react";
import { Modal, Box, Typography, Button } from "@mui/material";
import { CoursesContext } from "context/coursesContext";

const videoMappings = {
  Freemium: "6cV1oYCBeCQ",
  Flexible: "2w12o34gZKo",
  Basic: "6cV1oYCBeCQ",
  Pro: "D7n56vAEjd8",
};

export default function TutorialModal() {
  const [open, setOpen] = useState(false);
  const { userPlan } = useContext(CoursesContext);
  const result = videoMappings[userPlan.nombre] || "";

  useEffect(() => {
    const tutorialCount = parseInt(localStorage.getItem("tutorialCount")) || 0;
    const tutorialSkipped = localStorage.getItem("tutorialSkipped");

    if (!tutorialSkipped && tutorialCount < 3) {
      setOpen(true);
    }
  }, []);

  const handleClose = () => {
    const tutorialCount = parseInt(localStorage.getItem("tutorialCount")) || 0;
    localStorage.setItem("tutorialCount", tutorialCount + 1);
    setOpen(false);
  };

  const handleSkipForever = () => {
    localStorage.setItem("tutorialSkipped", "true");
    setOpen(false);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-11/12 sm:w-3/4 lg:w-1/2 bg-[#1B212B] p-6 rounded-lg shadow-lg">
        <Typography
          variant="h6"
          component="h2"
          className="text-xl font-bold text-[#7DB8F2] font-roboto"
        >
          Welcome to the DigiSpeak platform tutorial
        </Typography>
        <lite-youtube videoid={result} videotitle="This is a video title">
          <a
            className="lite-youtube-fallback"
            href={`https://www.youtube.com/watch?v=${result}`}
          >
            Watch on YouTube: "Welcome to the DigiSpeak platform"
          </a>
        </lite-youtube>
        <div className="flex justify-end mt-4 gap-3">
          <Button
            variant="contained"
            color="primary"
            onClick={handleClose}
            className="!bg-[#CF7831] hover:!bg-[#30CFC7] font-roboto"
          >
            Got it
          </Button>
          <Button
            variant="outlined"
            color="error"
            onClick={handleSkipForever}
            className="!border-red-500 !text-red-500 hover:!bg-red-100 font-roboto"
          >
            Don't show this again
          </Button>
        </div>
      </Box>
    </Modal>
  );
}
