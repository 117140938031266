import {
  Box,
  Button,
  Grid,
  InputBase,
  Typography,
  Tooltip,
  Fade,
  tooltipClasses,
  styled,
} from "@mui/material";
import { SnackBarActions } from "components/SnackBarActions";
import { useForm } from "hooks/useForm";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { postFreemiumRegister } from "services/postFreemiumRegister";
import { Notification } from "components/registration-notice";
import usePasswordValidation from "hooks/usePasswordValidation";
import QuestionCard from "components/question-card/question-card";
import questions from "./questions.json";
import briefcase from "assets/briefcase.png";
import books from "assets/books.png";
import reloj from "assets/reloj.png";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";

const iconMapping = {
  briefcase: briefcase,
  books: books,
  reloj: reloj,
};

const initialSnackBar = {
  isSnackBarOpen: false,
  severity: "success",
  message: "Alumno registrado correctamente",
};

const errorSnackbar = {
  isSnackBarOpen: true,
  severity: "error",
  message: "Ha ocurrido un error",
};

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} disableHoverListener />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.common.white,
  }
}));

export const UserRegisterPage = () => {
  const {
    name,
    lastName,
    email,
    password,
    confirmPassword,
    onInputChange,
    onResetForm,
  } = useForm({
    name: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
  });

  const [snackBarInfo, setSnackBarInfo] = useState(initialSnackBar);
  const [renderNotice, setRenderNotice] = useState(false);
  const [passwordMatch] = useState(true);
  const [step, setStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState(null);
  const [answered, setAnswered] = useState(false);
  const [renderButtonTest, setRenderButtonTest] = useState(false);
  const [searchParams] = useSearchParams();
  const campaignSource = searchParams.get("campaign_source") || "";
  const { isValid: isPasswordValid, validations } =
    usePasswordValidation(password);

  const closeSnackbar = () => {
    setSnackBarInfo({
      ...snackBarInfo,
      isSnackBarOpen: false,
    });
  };

  const handleNextStep = (e) => {
    if (step === 0) {
      const isValid = checkForm(e);
      if (!isValid) return;
    }

    if (step <= questions.length) {
      setStep(step + 1);
      setAnswered(false);
    }
  };

  const checkForm = () => {
    if (
      name === "" ||
      lastName === "" ||
      email === "" ||
      password === "" ||
      confirmPassword === "" ||
      isPasswordValid === false
    ) {
      setSnackBarInfo({
        ...errorSnackbar,
        message: "Por favor completa los datos",
      });
      return false;
    }
    return true;
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    if (!selectedOption) {
      setSnackBarInfo({
        ...errorSnackbar,
        message: "Por favor, selecciona una opción para esta pregunta.",
      });
      return;
    }

    const formData = {
      name,
      lastname: lastName,
      email,
      password,
      level: selectedOption,
      campaignSource,
    };

    const res = await postFreemiumRegister(formData);
    if (!res.ok) {
      const jsonResponse = await res.json();
      if (jsonResponse.message === "El email ya está registrado.") {
        setSnackBarInfo({
          ...errorSnackbar,
          message: jsonResponse.message,
        });
        return;
      }
      return setSnackBarInfo({ ...errorSnackbar, message: jsonResponse });
    }
    onResetForm();
    setRenderNotice(true);
  };

  const handleTestClick = async (e) => {
    e.preventDefault();
    const formData = {
      name,
      lastname: lastName,
      email,
      password,
      level: selectedOption,
      campaignSource,
    };

    const res = await postFreemiumRegister(formData);
    if (!res.ok) {
      const jsonResponse = await res.json();
      if (jsonResponse.message === "El email ya está registrado.") {
        setSnackBarInfo({
          ...errorSnackbar,
          message: jsonResponse.message,
        });
        return;
      }

      return setSnackBarInfo({ ...errorSnackbar, message: jsonResponse });
    }
    const jsonResponse = await res.json();
    const token = jsonResponse;
    window.location.href = `${process.env.REACT_APP_DIGISPEAK_ENGLISH_TEST}${token}`;
    onResetForm();
  };

  const getConfirmPasswordColor = () => {
    if (confirmPassword === "") return "#efefef";
    return password === confirmPassword ? "#98fb98" : "#ffcccb";
  };

  const renderValidationStatus = (isValid) =>
    isValid ? (
      <CheckCircleIcon color="success" />
    ) : (
      <CancelIcon color="error" />
    );

  const handleOptionSelect = (value) => {
    if (step < questions.length) {
      setAnswered(true);
    }
    if (step === questions.length) {
      if (value === "") {
        setSelectedOption(value);
        setRenderButtonTest(true);
      } else {
        setSelectedOption(value);
        setRenderButtonTest(false);
      }
    }
  };
  return (
    <>
      {renderNotice ? (
        <Notification email={email} />
      ) : (
        <Grid
          container
          minHeight={"100vh"}
          justifyContent={"center"}
          alignItems="center"
          py={10}
        >
          <Box
            component="form"
            onSubmit={(e) => e.preventDefault()}
            sx={{
              width: { xs: "95%", sm: "90%" },
              maxWidth: "700px",
              backgroundColor: "#fff",
              borderRadius: "6px",
              p: 4,
              boxShadow: "0 0 15px rgba(0,0,0, 0.03)",
            }}
          >
            {step === 0 && (
              <>
                <Typography variant="h4" fontWeight={600} color={"#000"} mb={3}>
                  Registrarse
                </Typography>
                <Grid item xs={12} my={1}>
                  <InputBase
                    label="Nombre"
                    placeholder="Nombre"
                    variant="outlined"
                    name="name"
                    value={name}
                    onChange={onInputChange}
                    fullWidth
                    sx={inputSx}
                  />
                </Grid>
                <Grid item xs={12} my={1}>
                  <InputBase
                    label="Apellido"
                    variant="outlined"
                    placeholder="Apellido"
                    name="lastName"
                    value={lastName}
                    onChange={onInputChange}
                    fullWidth
                    sx={inputSx}
                  />
                </Grid>
                <Grid item xs={12} my={1}>
                  <InputBase
                    label="Correo"
                    type="email"
                    variant="outlined"
                    placeholder="Correo Electronico"
                    name="email"
                    value={email}
                    onChange={onInputChange}
                    fullWidth
                    sx={inputSx}
                  />
                </Grid>
                <Grid item xs={12} my={1}>
                  <LightTooltip
                    placement="left"
                    slots={{
                      transition: Fade,
                    }}
                    slotProps={{
                      transition: { timeout: 600 },
                    }}
                    title={
                      <div>
                        <b>
                          <p>
                            {renderValidationStatus(validations.length)}{" "}
                            {validations.length
                              ? "Al menos 8 caracteres"
                              : "Mínimo 8 caracteres"}
                          </p>
                          <p>
                            {renderValidationStatus(validations.hasUpperCase)}{" "}
                            {validations.hasUpperCase
                              ? "Al menos 1 letra mayúscula"
                              : "1 letra mayúscula requerida"}
                          </p>
                          <p>
                            {renderValidationStatus(validations.hasSpecialChar)}{" "}
                            {validations.hasSpecialChar
                              ? "Al menos 1 carácter especial"
                              : "1 carácter especial requerido"}
                          </p>
                          <p>
                            {renderValidationStatus(validations.hasNumber)}{" "}
                            {validations.hasNumber
                              ? "Al menos 1 número"
                              : "1 número requerido"}
                          </p>
                        </b>
                      </div>
                    }
                    arrow
                  >
                    <InputBase
                      label="Contraseña"
                      type="password"
                      variant="outlined"
                      placeholder="Contraseña"
                      name="password"
                      value={password}
                      onChange={onInputChange}
                      fullWidth
                      sx={{
                        ...inputSx,
                        backgroundColor: passwordMatch ? "#efefef" : "#ffcccb",
                      }}
                    />
                  </LightTooltip>
                </Grid>
                <Grid item xs={12} my={1}>
                  <InputBase
                    label="Confirmar Contraseña"
                    type="password"
                    variant="outlined"
                    placeholder="Confirmar Contraseña"
                    name="confirmPassword"
                    value={confirmPassword}
                    onChange={onInputChange}
                    fullWidth
                    sx={{
                      ...inputSx,
                      backgroundColor: getConfirmPasswordColor(),
                    }}
                  />
                </Grid>
              </>
            )}
            {step > 0 &&
              step <= questions.length &&
              (answered ? (
                <QuestionCard
                  feedback={questions[step - 1].feedback}
                  icon={iconMapping[questions[step - 1].icon]}
                />
              ) : (
                <QuestionCard
                  question={questions[step - 1].question}
                  options={questions[step - 1].options}
                  onOptionSelect={handleOptionSelect}
                  icon={iconMapping[questions[step - 1].icon]}
                />
              ))}
            <Grid item my={2} container spacing={2} justifyContent="center">
              {step > 0 && (
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => setStep(step - 1)}
                    sx={{
                      mt: 2,
                      borderRadius: "8px",
                      marginRight: 2,
                    }}
                  >
                    Atrás
                  </Button>
                </Grid>
              )}
              {step < questions.length ? (
                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={handleNextStep}
                    sx={{
                      mt: 2,
                      borderRadius: "8px",
                      marginRight: 2,
                      "&:hover": {
                        backgroundColor: "#2de678",
                      },
                    }}
                  >
                    Siguiente
                  </Button>
                </Grid>
              ) : (
                <>
                  {renderButtonTest && (
                    <Grid item>
                      <Button
                        variant="outlined"
                        type="button"
                        onClick={handleTestClick}
                        disabled={!!selectedOption}
                        sx={{
                          mt: 2,
                          borderRadius: "8px",
                          marginRight: 2,
                          "&:hover": {
                            backgroundColor: "#2de678",
                          },
                        }}
                      >
                        Realizar test
                      </Button>
                    </Grid>
                  )}
                  <Grid item>
                    <Button
                      variant="outlined"
                      type="button"
                      onClick={onSubmit}
                      disabled={renderButtonTest}
                      sx={{
                        mt: 2,
                        borderRadius: "8px",
                        marginRight: 2,
                        "&:hover": {
                          backgroundColor: "#2de678",
                        },
                      }}
                    >
                      Terminar Registro
                    </Button>
                  </Grid>
                </>
              )}
            </Grid>
          </Box>
          <SnackBarActions handleSnackbar={closeSnackbar} {...snackBarInfo} />
        </Grid>
      )}
    </>
  );
};

const inputSx = {
  backgroundColor: "#efefef",
  py: 0.5,
  pl: 2,
  borderRadius: "8px",
  ":focus": { outline: "1px solid #ccc" },
  my: 1,
};
