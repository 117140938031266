import DashBoarMyCourses from "../DashBoardMyCourses";
import { useContext } from "react";
import { CoursesContext } from "context/coursesContext";
import TutorialModal from "components/tutorial-modal";
const validNames = ["Freemium","Basic", "Flexible", "Pro"];
export default function DashboardSection() {
  const user = JSON.parse(window.localStorage.getItem("loggedAppUser"));
  const username = user.nombre.split(" ").slice(0, 1);
  const { userPlan } = useContext(CoursesContext);
  const isValid = validNames.includes(userPlan.nombre);
  return (
    <section className="flex flex-col size-full h-full justify-start align-middle gap-5 p-5 pt-8 pb-14 md:p-10 xl:px-14 bg-[#1B212B]">
      <h2 className="text-[#7DB8F2] text-[26px] font-roboto font-[700]">
        Welcome Back {username}
      </h2>
      <h4 className="text-[#7DB8F2] text-[24px] font-roboto font-[700]">
        Shortcuts
      </h4>
      <div className="flex flex-col lg:flex-row gap-5 mt-16">
        <DashBoarMyCourses
          clases="mx-auto"
          ruta="/my-courses"
          nombre="My courses"
        />
        <DashBoarMyCourses ruta="/my-progress" nombre="My progress" />
        {isValid && <DashBoarMyCourses ruta="/video-tutorial" nombre="How to learn" />}
      </div>
      {isValid && <TutorialModal />}
    </section>
  );
}
