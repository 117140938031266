import React, { useState, useContext } from "react"
import { useLocation } from 'react-router-dom';

import { Link } from "wouter"

import "./styles.css"
import CourseIcons from "components/CourseIcons"
import { CoursesContext } from "context/coursesContext"
import { useAccess } from "hooks/useAccess"
import { UIContext } from "context/UIContext"
import { UpgradePlanPage } from "components/UpgradePlanPage";

const isModuleCompleted = (module) => {
     // Verifica si todas las clases en el módulo están completadas
     return module.clases.every(clase => clase.completada);
}

const MaterialList = ({ course, module }) => {
     return module.clases
          .sort((a, b) => (a.claseNumero > b.claseNumero ? 1 : -1))
          .map((clase, index) => {
               return (
                    <li key={clase.id}>
                         <Link href={`/courses-paced/${course}/module/${module.id}/material/${clase.id}`}>
                              <div className='flex h-64 w-56 flex-col rounded-xl border border-[#7DB8F2] bg-transparent shadow-md  hover:border-[#CF7831]'>
                                   <div className='relative overflow-hidden rounded-t-xl'>
                                        <img
                                             src={clase.thumbnails}
                                             alt={clase.nombre}
                                             className={`h-36 object-cover ${clase.completada ? "blur-sm" : "blur-none"}`}
                                        />
                                        <div className='absolute left-0 top-0 z-10 h-full w-full bg-black opacity-10'></div>
                                        {clase.completada ? (
                                             <>
                                                  <div className='bg-accent absolute left-1/2 top-1/2 flex -translate-x-1/2 -translate-y-1/2 items-center rounded-full p-2'>
                                                       <span className='text-primary mr-1 font-semibold'>
                                                            Complete
                                                       </span>
                                                       <CourseIcons name='check' />
                                                  </div>
                                             </>
                                        ) : (
                                             <></>
                                        )}
                                   </div>
                                   <div className='flex w-full justify-between overflow-hidden rounded-b-lg'>
                                        <div className='flex  p-5 font-semibold'>
                                             <h3 className='font-roboto text-[#7DB8F2] font-semibold m-auto'>
                                                  {clase.nombre}
                                             </h3>
                                        </div>
                                        <div className=' text-[#7DB8F2] flex h-28 items-center justify-center border-l border-[#7DB8F2] p-3 text-2xl font-semibold hover:border-[#CF7831]'>
                                             <span>{clase.claseNumero}</span>
                                        </div>
                                   </div>
                              </div>
                         </Link>
                    </li>
               )
          })
}

function AccordionItem({ course, module, index }) {
     const [isActive, setActive] = useState(isModuleCompleted(module))
     const [isComplete, setComplete] = useState(isModuleCompleted(module));
     const location = useLocation();
     const locationHash = location.hash ? location.hash.slice(1) : null;
     const moduleHash = 'module-' + module.id
     return (
          <div id={moduleHash} class={`bg-transparent ${isActive && isComplete ? "rounded-xl border-[#CF7831] border-2" : "rounded-xl border-slate-200 border-2"} p-2 shadow-sm transition-all duration-300 ease-in-out`}>
               <div class={`rounded-xl border-gray-200 p-3 ${locationHash === moduleHash ? 'bg-hl' : ''}`}>
                    <li key={module.id} className="accordion-item text-primary">
                         <div className='flex w-full items-center justify-between' onClick={() => setActive(!isActive)}>
                              <div className='flex items-center'>
                                   <h2 className='accordion-title font-roboto text-[#7DB8F2] mr-5 text-center text-lg font-semibold'>
                                        {module.nombre}
                                   </h2>
                              </div>

                              {isActive ? <CourseIcons name='minus' /> : <CourseIcons name='plus' />}
                         </div>
                         <ol
                              className={`accordion-content flex flex-wrap  justify-center gap-5 sm:justify-start ${isActive
                                   ? "display mt-10 max-h-min"
                                   : "m-0 max-h-0 overflow-hidden opacity-0"
                                   } mb-1 transition-all duration-500 ease-in-out`}
                         >
                              <MaterialList course={course} module={module} />
                         </ol>
                    </li>
               </div>
          </div>
     )
}

function Module({ course, modules = [] }) {
     return modules ? modules.map((module, index) => (
          <AccordionItem key={module.id} course={course} module={module} index={index} />
     )) : <UpgradePlanPage />
}

export default function CoursePacedPage({ params, requiredPlanFeature }) {
     const id = params.courseId
     const { courses, userPlan } = useContext(CoursesContext) || []
     const course = courses.filter((course) => course.id === parseInt(id))[0]
     const hasRequiredPlan = userPlan && userPlan.features.find(item => {
          // si el plan del usuario no tiene este setting activo, no podra acceder a la funcionalidad
          return item.featureName === requiredPlanFeature && item.enabled;
     });
     return (hasRequiredPlan ?
          <section className='p-5 md:p-10'>
               {course ? (
                    <>
                         <h1 className='font-roboto text-[#7DB8F2] mr-5 ml-5 text-2xl font-semibold'>
                              {course.nombre}
                         </h1>
                         <ol className='accordion flex flex-col gap-3 p-5 border-collapse'>
                              {<Module course={course.id} modules={course.modulos} />}
                         </ol>
                    </>
               ) : (
                    <></>
               )}
          </section> :
          <UpgradePlanPage />
     )
}
