import React, { useContext } from "react";
import { Redirect, Route, Switch } from "wouter";

import MeetingsProvider from "context/MeetingsProvider";
import { SizeContext } from "context/SizeContext";

import SideBar from "components/SideBar";
import Header from "components/Header";
import { BasicPlanModal } from "components/BasicPlanModal";

import DashboardPage from "pages/Dashboard";
import CoursesPage from "pages/Courses";
import CoursePage from "pages/Course";
import MaterialPage from "pages/Material";
import AdditionalMaterialPage from "pages/AdditionalMaterial";
import ProfilePage from "pages/Profile";
import { MyCommunityPage } from "pages/MyCommunity";
import MyTopicPage from "pages/MyTopic";
import SpeakingPage from "pages/Speaking";
import { CoursesProvider } from "context/coursesContext";
import JitsiMeetPage from "pages/JitsiMeet";
import { BubblePage } from "pages/BubblePage";
import CoursePacedPage from "pages/CoursePaced";

import TestsPage from "pages/Tests";
import ClassMaterialPage from "pages/ClassMaterial/ClassMaterialPage";
import MyProgress from "pages/MyProgress";
import MyCourses from "pages/MyCourses";
import CourseMaterial from "pages/CourseMaterial";
import { USER_DATA } from "services/settings";
import { VideoTutorial } from "pages/video-tutorial";
export const StudentView = () => {
  const { showBar } = useContext(SizeContext);

  return (
    <>
      <CoursesProvider>
        <MeetingsProvider>
          <BasicPlanModal />
          <div
            className={`App size-full h-full tranisition-all flex flex-col items-center ${
              showBar ? "md:ml-60" : "md:ml-12"
            }`}
          >
            <SideBar />
            <Header user={USER_DATA} />
            <main className="w-full h-full">
              <Switch>
                <Route component={DashboardPage} path="/" />
                <Route path="/my-Courses">
                  {(params) => (
                    <MyCourses
                      url={"my-courses"}
                      params={params}
                      defaultSearch={true}
                      requiredPlanFeature={"learning_path"}
                    />
                  )}
                </Route>
                <Route path="/courses">
                  {(params) => (
                    <CoursesPage
                      url={"courses"}
                      params={params}
                      defaultSearch={false}
                      requiredPlanFeature={"learning_path"}
                    />
                  )}
                </Route>
                <Route path="/courses-paced">
                  {(params) => (
                    <CoursesPage
                      url="courses-paced"
                      params={params}
                      defaultSearch={false}
                      requiredPlanFeature={"library"}
                    />
                  )}
                </Route>
                <Route path="/courses/:courseId">
                  {(params) => (
                    <CoursePage
                      url={"courses"}
                      params={params}
                      requiredPlanFeature={"learning_path"}
                    />
                  )}
                </Route>
                <Route path="/courses-paced/:courseId">
                  {(params) => (
                    <CoursePacedPage
                      url="courses-paced"
                      params={params}
                      requiredPlanFeature={"library"}
                    />
                  )}
                </Route>
                <Route path="/courses-paced/bubble/:courseId/:moduleId">
                  {(params) => (
                    <BubblePage
                      params={params}
                      url={"courses-paced"}
                      requiredPlanFeature={"library"}
                    />
                  )}
                </Route>
                <Route path="/courses/bubble/:courseId/:moduleId">
                  {(params) => (
                    <BubblePage
                      params={params}
                      url={"courses"}
                      requiredPlanFeature={"learning_path"}
                    />
                  )}
                </Route>
                <Route path="/courses/:courseId/module/:moduleId/material/:materialId/:bubbleId">
                  {(params) => (
                    <MaterialPage
                      url={"courses"}
                      params={params}
                      requiredPlanFeature={"learning_path"}
                    />
                  )}
                </Route>
                <Route path="/courses-paced/:courseId/module/:moduleId/material/:materialId">
                  {(params) => (
                    <MaterialPage
                      url={"courses-paced"}
                      community={false}
                      params={params}
                      requiredPlanFeature={"library"}
                    />
                  )}
                </Route>
                <Route path="/additional-material">
                  <AdditionalMaterialPage
                    requiredPlanFeature={"additional_material"}
                  />
                </Route>
                <Route path="/my-progress">
                  {(params) => (
                    <MyProgress
                      url={"my-progress"}
                      params={params}
                      requiredPlanFeature={"learning_path"}
                    />
                  )}
                </Route>
                <Route path="/course-material">
                  {(params) => <CourseMaterial url={"course-material"} />}
                </Route>

                <Route path="/class-material">
                  <ClassMaterialPage requiredPlanFeature="class_material" />
                </Route>

                <Route component={ProfilePage} path="/profile" />
                <Route path="/speaking">
                  <SpeakingPage requiredPlanFeature="speaking_sessions" />
                </Route>
                <Route path="/tests">
                  <TestsPage requiredPlanFeature={"learning_path"} />
                </Route>
                <Route component={MyCommunityPage} path="/community" />
                <Route component={MyTopicPage} path="/community/:topicId" />
                <Route component={JitsiMeetPage} path="/JitsiMeet/:jitsiId" />
                <Route path="/video-tutorial">
                  <VideoTutorial />
                </Route>
                <Route>
                  <Redirect href="/" />
                </Route>
              </Switch>
            </main>
          </div>
        </MeetingsProvider>
      </CoursesProvider>
    </>
  );
};
