import "./video-style.css";
import { CoursesContext } from "context/coursesContext";
import { useContext } from "react";

const videoMappings = {
  Freemium: "6cV1oYCBeCQ",
  Flexible: "2w12o34gZKo",
  Basic: "6cV1oYCBeCQ",
  Pro: "D7n56vAEjd8",
};

export const VideoTutorial = () => {
  const { userPlan } = useContext(CoursesContext);
  const result = videoMappings[userPlan.nombre] || "";
  return (
    <section className="flex flex-col size-full h-full justify-center items-center p-5 pt-8 pb-14 md:p-10 xl:px-14">
      <div className="flex flex-col justify-center items-center w-full max-w-[720px] lg:max-w-[1080px]">
        <h2 className="text-[#7DB8F2] text-[26px] font-roboto font-[700]">
          How to learn
        </h2>
        <lite-youtube videoid={result} videotitle="This is a video title">
          <a
            className="lite-youtube-fallback"
            href={`https://www.youtube.com/watch?v=${result}`}
          >
            Watch on YouTube: "Welcome to the DigiSpeak platform"
          </a>
        </lite-youtube>
      </div>
    </section>
  );
};
