import { useState, useEffect } from 'react';

const usePasswordValidation = (password) => {
  const [validations, setValidations] = useState({
    length: false,
    hasUpperCase: false,
    hasSpecialChar: false,
    hasNumber: false,
  });

  useEffect(() => {
    const validatePassword = () => {
      const minLength = 8;
      const lengthValid = password.length >= minLength;
      const upperCaseValid = /[A-Z]/.test(password);
      const specialCharValid = /[!@#$%^&*(),.?":{}|<>[\]´`~\-_/\\]/.test(password);
      const numberValid = /[0-9]/.test(password);
      
      setValidations({
        length: lengthValid,
        hasUpperCase: upperCaseValid,
        hasSpecialChar: specialCharValid,
        hasNumber: numberValid,
      });
    };

    validatePassword();
  }, [password]);

  const isValid = Object.values(validations).every(Boolean);
  
  return { isValid, validations };
};

export default usePasswordValidation;